import { inject, Injectable, signal } from '@angular/core';
import { createEffect } from 'ngxtension/create-effect';
import { switchMap, tap } from 'rxjs';

import { StatsService } from '../../core/stats/stats.service';

@Injectable()
export class AdminStatsStore {
  private statsService = inject(StatsService);

  stats = signal({ affiliateCount: 0, brokerCount: 0, userCount: 0, lenderCount: 0, leadCount: 0, applicationCount: 0, fundedCount: 0 });

  loadStats = createEffect<void>(
    switchMap(() => {
      return this.statsService.getCounts({ getAffiliateCount: true, getBrokerCount: true, getUserCount: true, getLenderCount: true }).pipe(
        tap({
          next: r => {
            // console.log('AdminStatsStore.loadStats()', r);
            this.stats.set(r.result);
          },
          error: err => {
            console.error('AdminStatsStore.loadStats()', err);
            this.stats.set({ affiliateCount: 0, brokerCount: 0, userCount: 0, lenderCount: 0, leadCount: 0, applicationCount: 0, fundedCount: 0 });
          },
        })
      );
    })
  );
}
